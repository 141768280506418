body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.css-i2n9wu-MuiPaper-root {
  box-shadow: none !important;
}
.loader-custom-white:before,
.loader-custom-white:after {
  background: #fff !important;
}

.loader-custom-dark:before,
.loader-custom-dark:after {
  background: #060606 !important;
}

.loader-custom {
  transform: scale(0.8);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #e9a317;
  position: relative;
}
.loader-custom:before,
.loader-custom:after {
  content: '';
  position: absolute;
  border-radius: 50%;
  inset: 0;
  background: #060606;
  transform: rotate(0deg) translate(30px);
  animation: rotate 1s ease infinite;
}
.loader-custom:after {
  animation-delay: 0.5s;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg) translate(30px);
  }
}
div[role='tabpanel'] {
  box-shadow: none !important;
}
.game-card__cart-no:before {
  display: none !important;
}
body {
  background-color: #f2f2f2;
}
.article-content h3 {
  font-weight: 600 !important;
}

.article-content img {
  display: block;
  width: 100%;
  border-radius: 16px;
}
.article-content h2 {
  font-size: 23px;
  margin-top: 5px;
  margin-bottom: -10px;
  font-weight: 500 !important;
}
