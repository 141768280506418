.game {
  width: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0px;
  // padding-bottom: 50px;
  background-position: 50% 50%;
}
@media (max-width: 600px) {
  .game {
    padding-bottom: 0;
  }
}

.game__content {
  z-index: 1;
  position: relative;
}
.game__desc {
  font-size: 14px;
  line-height: 1.5;
}
.game__title {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: -15px;
}
@media (max-width: 1000px) {
  .game__title {
    font-size: 16px;
  }
}
.custom-radio {
  flex-wrap: wrap;
}

.game__text {
}
.game__list {
  font-size: 14px;
  padding-left: 16px;
}
.custom-radio-label {
  font-size: 18px;
  font-weight: 600 !important;
  // margin-bottom: 8px;
}
.check-id-box {
  display: flex;
  align-items: start;
  flex-direction: column;
  // flex-wrap: wrap;
  padding-bottom: 5px;
  padding-right: 36px;
  // border-bottom: 2px solid #2e3234;
}
@media (max-width: 600px) {
  .check-id-box {
    width: 100%;
    align-items: start;
    // max-width: 300px;
    margin: 0 auto;
    padding-right: 0;
    flex-direction: column;
  }
  .check-id-input {
    box-sizing: border-box;
    display: block;
    min-width: 0 !important;
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 16px;
  }
}
.check-id-label {
  display: flex;
  align-items: center;
  // flex-basis: 100%;
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 600 !important;
  & span {
    font-weight: 600 !important;
  }
}
.check-id-input {
  display: block;
  height: 36px;
  padding: 0;
  outline: none !important;
  border-radius: 4px;
  font-size: 24px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 300px;
  width: 100%;
  border: 2px solid #e2ba7e !important;
  margin-right: 20px;
  &:disabled {
    background-color: rgba(256, 256, 256, 1) !important;
    opacity: 0.7;
  }
}
.check-id-btn {
  transition: all 0.3s;
  padding-left: 18px;
  padding-right: 18px;
  height: 40px;

  //   padding-top: 6px;
  font-weight: 400;
  //   padding-bottom: 8px;
  font-size: 24px;
  padding-bottom: 2px;
  background-color: #9c2628;
  border: none;
  outline: 0;
  color: #e2ba7e;
  cursor: pointer;
  &:disabled {
    cursor: auto;
  }
  &:hover {
    background-color: #e2ba7e;
    color: #9c2628;
  }
}
.accept-checkbox {
  margin-top: 12px;
  user-select: none;
  cursor: pointer;
  & input {
    display: none;
  }
  & input:checked ~ span::after {
    display: block;
  }
  & span {
    display: block;
    // display: flex;
    // align-items: start;
    padding-left: 32px;
    position: relative;
    font-size: 12px;
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      display: block;
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      background-color: #ad2305;
      margin-right: 12px;
      margin-top: 1px;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 5px;
      left: 4px;
      background-color: #e2ba7e;
      width: 12px;
      height: 12px;
      display: none;
    }
  }
}
.game__card-grid {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 220px);
  column-gap: 15px;
  row-gap: 30px;
  // margin-bottom: 70px;
  // margin-top: 30px;
}
$primary-color: #ad2305; // Change color here. C'mon, try it!
$text-color: mix(#000, $primary-color, 64%);
.custom-radio {
  display: flex;

  label {
    padding-right: 8px;
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
    /* Accessible outline */
    /* Remove comment to use */
    /*
		&:focus-within {
				outline: .125em solid $primary-color;
		}
	*/
    input {
      position: absolute;
      left: -9999px;
      &:checked + span {
        border: 1px solid #e2ba7e;
        background-color: $primary-color;
        color: #e2ba7e;
        &:before {
          background-color: #e2ba7e;
          box-shadow: inset 0 0 0 0.4375em $primary-color;
        }
      }
    }
    span {
      border: 1px solid transparent;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      padding: 0.375em 0.75em 0.375em 0.375em;
      border-radius: 99em; // or something higher...
      transition: 0.25s ease;
      color: #e2ba7e;
      &:hover {
        color: #e2ba7e;
        background-color: $primary-color;
        &::before {
        }
      }
      &:before {
        display: flex;
        flex-shrink: 0;
        content: '';
        background-color: #e2ba7e;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        margin-left: 0.375em;
        transition: 0.25s ease;
        box-shadow: inset 0 0 0 0.125em $primary-color;
      }
    }
  }

  // Codepen spesific styling - only to center the elements in the pen preview and viewport
  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  // End Codepen spesific styling
}
