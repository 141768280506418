.hero-slide {
  height: 480px !important;
  //   display: flex;
  position: relative;
  //   overflow: hidden;
}

.slide-image {
  width: calc(100% + 32px);
  height: 480px;
  width: 100%;
  top: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  & img {
    height: 480px;
    width: 100%;
    object-fit: cover;
  }
}
.slider-button {
  display: block;
  background-color: #44d62c;
  border: 0;
  height: 30px;
  width: 100% !important;
  max-width: 240px;
}
.swiper-pagination-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  & .swiper-pagination-bullet {
    margin: 0 14px !important;
    width: 12px;
    height: 12px;
    opacity: 1;
    background: #ccc;
  }
  & .swiper-pagination-bullet-active {
    background-color: #e2ba7e;
  }
}
.hero-slide .content {
  box-shadow: inset 0 -193px 70px -95px #111;
  position: relative;
  z-index: 2;
  height: 480px;
}
@media (max-width: 1000px) {
  .hero-slide .content {
    height: 200px;
  }
  .hero-slide {
    height: 200px !important;
  }
  .slide-image {
    height: 200px !important;
    & img {
      height: 200px;
    }
  }
  .swiper-pagination-custom {
    padding-top: 20px;
  }
}
