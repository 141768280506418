.item-game {
  //   width: 163px;
  padding: 6px;
  &__img-box {
    width: 130px;
    height: 130px;
  }

  &__img {
    overflow: hidden;
    display: block;
    border-radius: 24px;
    width: 100%;
  }

  &__name {
    text-align: center;
    margin-top: 8px;
    font-size: 11.5px;
    font-weight: 500 !important;
  }
}
