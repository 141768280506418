.game-card {
  transition: background-color 0.5s;
  padding: 20px;
  color: #000;
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.11);
  position: relative;
  &:hover,
  &--active {
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
  }
  &__old-price {
    opacity: 0.5;
    text-decoration: line-through;
    // margin-bottom: 2px;
    font-size: 14px;
    margin-left: 5px;
    margin-top: 1px;
  }
  &__old-price-big {
    // font-size: 14px !important;
  }
  &__old-price-very-big {
    font-size: 12px;
  }
  &__img {
    width: 80%;
    margin: 0 auto;
    display: block;
  }
  &__discount {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ffd54f;
    font-weight: 500 !important;
    color: #000 !important;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 8px;
  }
  &__middle {
    margin-top: 5px;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  &__bottom {
    padding-top: 18px;
    margin-top: 18px;
    border-top: 1px solid #464545;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__price-box {
    display: flex;
    align-items: center;
  }
  &__price {
    font-size: 18px;
    font-weight: 600 !important;
  }

  &__price-big {
    font-size: 16px;
  }
  &__price-very-big {
    font-size: 14px;
  }
  &__btn-box {
    display: flex;
    align-items: center;
  }
  &__chat {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
    width: 85.81px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;
    background-color: #81c784;
    border: none;
    // width: 40px;
    padding: 0 10px;
    height: 30px;

    position: relative;
    margin-right: 6px;
    color: #fff;
  }
  &__cart {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
    display: flex;
    align-items: center;
    transition: opacity 0.3s;
    background-color: #9c2628;
    border: none;
    // width: 40px;
    padding: 0 10px;
    height: 30px;

    position: relative;
    margin-right: 6px;
    color: #e2ba7e;
    &::before {
      content: '';
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      margin-right: 4px;
      display: block;
      mask-image: url(../../public/cart.svg);
      mask-size: contain;
      background-color: #e2ba7e;
      opacity: 1;
      width: 20px;
      height: 20px;
    }
  }

  &__donate {
    transition: all 0.3s;
    cursor: pointer;
    height: 30px;
    background-color: #e08418;
    border: none;
    padding: 0 12px;
    font-size: 14px;
    padding-bottom: 1px;
    color: #000 !important;
    font-weight: 400 !important;
    &:not(:disabled):hover,
    &--active {
      background-color: #9c2628;
      color: #e2ba7e !important;
    }
    &:disabled {
      opacity: 0.5 !important;
    }
  }
}
.finish-donate {
  background-color: #e08418 !important;
  color: #000 !important;
  &:not(:disabled):hover,
  &--active {
    background-color: #9c2628 !important;
    color: #e2ba7e !important;
  }
  &:disabled {
    opacity: 0.5 !important;
  }
}
