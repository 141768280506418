:root {
  --header-height: 130.24px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}
#root,
body {
  height: 100vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
* {
  font-family: 'Fira Sans', sans-serif !important;
  font-weight: 400 !important;
}
.link {
  color: #0089ff;
  text-decoration: underline;
  display: inline-block;
}
html {
  scroll-behavior: smooth;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.vk-comments {
}
.vk-comments__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.vk-comments__item {
  padding: 14px;
  display: flex;
  align-items: start;
  padding-left: 0;
}

.vk-comments__avatar {
  display: block;
  margin-right: 10px;
  width: 34px;
  min-width: 34px;
  height: 34px;
  min-height: 34px;
  border-radius: 50%;
}

.vk-comments__content {
}
.vk-comments__img {
  padding-top: 10px;
  padding-bottom: 4px;
  max-width: 490px;
  width: 100%;
}
.vk-comments__name {
  display: block;
  text-decoration: none;
  line-height: 14px;
  font-weight: 500 !important;
  color: #e1e3e6;
  font-size: 12.5px;
  font-family: 'Roboto', sans-serif !important;
}

.vk-comments__text {
  display: block;
  line-height: 17.68px;
  font-size: 12.5px;
  padding-bottom: 4px;
  padding-top: 3px;
  font-weight: 400 !important;
  color: #e1e3e6;
  font-family: -apple-system, 'BlinkMacSystemFont', 'Roboto', 'Open Sans', 'Helvetica Neue', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer',
    'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif !important;
}

.vk-comments__date {
  line-height: 14px;
  font-size: 12.5px;

  color: #939393;
  font-family: -apple-system, 'BlinkMacSystemFont', 'Roboto', 'Open Sans', 'Helvetica Neue', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer',
    'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif !important;
}
.vk-comments__more {
  padding: 7px 16px 8px 16px;
  font-weight: 500 !important;
  font-family: -apple-system, 'BlinkMacSystemFont', 'Roboto', 'Open Sans', 'Helvetica Neue', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer',
    'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif !important;
  font-size: 12.5px;
  border-radius: 8px;
  background-color: #222;
  color: #fff;
  height: 40px;
  border: none;
  cursor: pointer;
  outline: 0;
  width: 100%;
}

.login-custom {
  cursor: pointer;
  outline: none;
  border: none;
  margin: 0 auto;
  width: 106px;
  text-align: center;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;

  color: var(--cp-color-1);
  display: block;
  z-index: 2;
  overflow: hidden;
  padding: 3px;
}

.login-custom div {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(115deg, #4fcf70, #fad648, #a767e5, #12bcfe, #44ce7b);
  background-size: 50% 100%;
  animation: qer 1.5s linear infinite;
  /* transition: all 0.4s linear; */
}
@keyframes qer {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-106px);
  }
}
.login-custom span {
  transform: scaleX(1.01) scaleY(1);
  position: relative;
  z-index: 2;
  padding: 6px 0;
  font-size: 12px;
  align-items: center;
  background: #0a0a0a;

  border-radius: 3px;
  display: block;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
}
#tsparticles {
  background: #000000a3;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 0; /* if you use -1 you have to set to `"window"` the interactivity.detectsOn property */
}
h4,
h5 {
  font-size: 20px !important;
}
.cart-moving {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}
#google-auth-box iframe {
}
#google-auth-box {
  margin-top: 7px !important;
}
